<template>
    <div class="menu-action language-select-wrapper">
        <a class="menu-action-inner" @click="$emit('toggle')">
            <span>{{ currentLanguage.name_local }}</span>
        </a>

        <ul class="light cl nw language-select-list oh" :class="animation ? 'active' : ''" ref="sub">
            <li v-for="lang in languages" class="cl nw">
                <a :href="lang.trans_url ? lang.trans_url : '/' + (lang.code === 'en' ? '' : lang.code)" @click="setCurrentLanguage(lang.trans_url ? lang.trans_url : '/' + (lang.code === 'en' ? '' : lang.code), event=$event, lang=lang)" :class="[lang.code == currentLanguage ? 'active' : '']" class="rw nw middle">
                    <svg v-if="lang.code == 'nl'" width="30" height="21" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="#D00" d="M0 0h30v7H0z"/><path fill="#012169" d="M0 14h30v7H0z"/></svg>
                    <svg v-if="lang.code == 'en'" width="30" height="21" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)"><path fill="#012169" d="M0 0h30v21H0z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M-1 13.878h4.243L-1 16v4c0 .225.037.441.106.643l12.332-6.167V21h7v-6.524l12.438 6.219A2 2 0 0 0 31 20v-3.94l-4.365-2.182H31v-7h-4.365L31 4.696V1c0-.322-.076-.625-.21-.895L18.438 6.281V0h-7v6.28L-.814.154A2.01 2.01 0 0 0-1 1v3.757l4.243 2.121H-1v7Z" fill="#fff"/><path fill-rule="evenodd" clip-rule="evenodd" d="m-1 3.974 5.81 2.904h6.26L-.994.846C-.998.896-1 .948-1 1v2.974Zm0 8.504v-4.2h13.838V0h4.2v8.278H31v4.2H17.038V21h-4.2v-8.522H-1Zm0 7.435v-3.13l5.809-2.905h6.261L-1 19.913Zm32-3.07v3.13l-12.19-6.095h6.26L31 16.843ZM30.989.788c.007.07.011.141.011.212v2.913l-5.93 2.965h-6.26L30.989.788Z" fill="#D00"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h30v21H0z"/></clipPath></defs></svg>
                    <svg v-if="lang.code == 'de'" width="30" height="21" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="#D00" d="M0 0h30v21H0z"/><path fill="#000" d="M0 0h30v7H0z"/><path fill="#FFCE00" d="M0 14h30v7H0z"/></svg>
                    <span>{{ lang.name_translated }}</span>
                </a>
            </li>
        </ul>
    </div>
</template>

<script setup>
    import gsap        from 'gsap'
    import * as cookie from '../cookie'
</script>

<script>
    export default {
        props: ['languages', 'currentLanguage', 'active'],

        data() {
            return {
                animation: false
            }
        },

        mounted() {
            gsap.set(this.$refs.sub, {
                opacity: 0,
                y: -16
            })
        },

        methods: {
            setCurrentLanguage(href, event, lang) {
                event.preventDefault()
                cookie.set('lang', lang.code)
                window.location.href = href
            }
        },

        watch: {
            active(val=false) {
                gsap.to(this.$refs.sub, 0.24, {
                    ease    : "expo.out",
                    opacity : val ? 1 : 0,
                    y       : val ? 0 : -16,

                    onComplete: () => {
                        this.animation = val
                    }
                })
            }
        }
    }
</script>
