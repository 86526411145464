// import Swiper, { Navigation, Pagination, FreeMode } from 'swiper'


import { Navigation, Mousewheel, Thumbs, EffectCoverflow } from 'swiper/modules'
import { Swiper } from 'swiper'
import { gsap } from 'gsap'

Swiper.use([Navigation, Mousewheel, Thumbs, EffectCoverflow])

export function swiper(el) {
	let type = el.dataset.swiper

	if (type == 'board') {
		let swiperInstance = new Swiper('#' + el.id, {
			slidesPerView: 'auto',
			spaceBetween: 16,
			speed: 200,
			navigation: {
				nextEl: '.swiper-nav-next',
				prevEl: '.swiper-nav-prev',
			}
		})

	} else if (type == 'gallery') {
		let swiperInstance = new Swiper('#' + el.id, {
			slidesPerView: 'auto',
			spaceBetween: 16,
			loopedSlides: 3,
			loop: true,
			speed: 200,
			breakpoints: {
				0 : {
					calculateHeight:true,
					autoHeight: true
				},
				720: {
					spaceBetween: 32,
				}
			},
			navigation: {
				nextEl: '.swiper-nav-next',
				prevEl: '.swiper-nav-prev',
			}
		})
	} else if (type == 'image-slider') {
		let swiperInstance = new Swiper('#' + el.id, {
			// modules: [Navigation, Pagination],
			slidesPerView: 'auto',
			spaceBetween: 16,
			// loopedSlides: 6,
			// loop: true,
			speed: 200,
			calculateHeight:true,
			autoHeight: true,
			breakpoints: {
				// 0 : {
				// },
				720: {
					spaceBetween: 40,
				}
			},
			pagination: {
			    el: '.swiper-pagination',
			    type: 'bullets',
			  },
			navigation: {
				nextEl: '.swiper-nav-next',
				prevEl: '.swiper-nav-prev',
			}
		})
	} else if (type == 'project-slider') {
		let root = document.querySelector('#' + el.id)
		let swiperInstance = new Swiper('#' + el.id, {
			// modules: [Navigation, Pagination],
			slidesPerView: 'auto',
			spaceBetween: 40,
			centeredSlides: true,
			initialSlide: 1,
			speed: 400,
			preventClicks: true,
			preventClicksPropagation: true,
			noSwiping: true,
			slideToClickedSlide: false,
			navigation: {
				nextEl: '.swiper-nav-next',
				prevEl: '.swiper-nav-prev',
			}
		})

		let videoControls = root.querySelectorAll('.video-toggle')

		videoControls.forEach((el, index) => {
			el.addEventListener('click', playPause, false)

			function playPause() {
				el.classList.toggle('active')
				let state = el.classList.contains('active')
				let video = el.querySelector('video') 
				state ? video.play() : video.pause() 
			}
		})
	} else if (type == 'stats-slider') {
		let root = document.querySelector('#' + el.id)
		let swiperInstance = new Swiper('#' + el.id, {
			// modules: [Navigation, Pagination],
			slidesPerView: 'auto',
			speed: 200,
		})

		let slides = root.querySelectorAll('.swiper-slide'),
			nth    = 0,
			max    = slides.length

		let progress = root.querySelector('.progress'),
			active   = root.querySelector('.state-current')

		if (active && progress) {
			gsap.to(progress, 0.4, {
				ease: "out.expo",
				css: {
					width: (active.offsetWidth / 2) + active.offsetLeft
				},
				delay: 0.3
			})
		}

	} else if (type == 'fullscreen') {
		let root = document.querySelector('#' + el.id)
		let swiperInstance = new Swiper('#' + el.id, {
			// modules: [Navigation],
			slidesPerView: 'auto',
			spaceBetween: 160,
			centeredSlides: true,
			initialSlide: 1,
			speed: 400,
			watchSlidesProgress: true,
			slideToClickedSlide: false,
			navigation: {
				nextEl: '.swiper-nav-next',
				prevEl: '.swiper-nav-prev',
			}
		})

		let lightboxImages = document.querySelectorAll('.open-lightbox')

		lightboxImages.forEach((el, index) => {
			el.addEventListener('click', openLightbox, false)

			function openLightbox() {
				root.classList.toggle('open')
				swiperInstance.slideTo(event.target.dataset.slide)
			}
		})

		let videoControls = root.querySelectorAll('.video-controls')

		videoControls.forEach((el, index) => {
			el.addEventListener('click', event => {
				stopAll(el.closest('.swiper-slide'))

				if (event.target === el) {
					let parent 	   = el.closest('.swiper-slide'),
						wrapper    = parent.querySelector('.video-wrapper'),
						muteButton = parent.querySelector('.toggle.mute'),
						muteState  = muteButton.classList.contains('active')

					wrapper.classList.toggle('active')

					let state = wrapper.classList.contains('active'),
						video = wrapper.querySelector('video') 

					state ? video.play() : video.pause() 
					video.mute = muteState
				}
			}, false)
		})

		function stopAll(el=false) {
			let videos = root.querySelectorAll('video')

			if (videos) {
				videos.forEach(video => {
					if (el === false || video !== el.querySelector('video')) {
						let wrap = video.closest('.swiper-slide').querySelectorAll('.active')

						wrap.forEach(wr => {
							wr.classList.remove('active')
						})

						video.pause()
						video.muted = true
					}
				})
			}
		}

		swiperInstance.on('transitionEnd', function() {
			stopAll()
		})

		let muteButtons = root.querySelectorAll('.toggle.mute')

		muteButtons.forEach(button => {
			button.addEventListener('click', (event) => {
				event.preventDefault()

				if (event.target === button) {
					button.classList.toggle('active')
					let state = button.classList.contains('active')
					let video = button.parentNode.querySelector('video')
					video.muted = !state
				}
			}, false)
		})

		let closeOverlay = root.querySelectorAll('.overlay, .close-overlay')

		closeOverlay.forEach(item => {
			item.addEventListener('click', (event) => {
				event.preventDefault()

				if (event.target === item) {
					root.classList.toggle('open')
					stopAll()
				}
			}, false)
		})

	} else if (type == 'featured') {
		let swiperInstance = new Swiper('#' + el.id, {
			slidesPerView: 'auto',
			spaceBetween: 16,
            observer: true,
            speed: 240,
            preventClicksPropagation: true,
            touchRatio: 1,
            slideToClickedSlide: true,
            roundLengths: true,
            allowTouchMove: true,
            virtualTranslate: false,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            
            Mousewheel: {
                forceToAxis: true,
                invert: false,
                releaseOnEdges: true,
                sensitivity: 10,
            },

			navigation: {
				nextEl: '.swiper-nav-next',
				prevEl: '.swiper-nav-prev',
			}
		})
	} else if (type == 'map') {
		let swiperInstance = new Swiper('#' + el.id, {
			// modules: [FreeMode],
			slidesPerView: 'auto',
			spaceBetween: 16,
			speed: 200,
			loop: true,
			loopedSlides: 3,
			freeMode: {
				enabled: true,
				sticky: false,
				minimumVelocity: 0,
				momentumBounce: true,
				momentumBounceRatio: 0.2,
				momentumRatio: 0.2,
				momentumVelocityRatio: 0.2,
			},
		    // spaceBetween: 0,
		    // slidesPerView: 1,
		    centeredSlides: true,
		    // roundLengths: true,
		})
	} else if (type == 'kpi') {
		let center = el.classList.contains('center')
		let swiperInstance = new Swiper('#' + el.id, {
			slidesPerView: 'auto',
			centeredSlides: center, 
			speed: 200,
			navigation: {
				nextEl: '.swiper-nav-next',
				prevEl: '.swiper-nav-prev',
			}
		})
	} else if (type == 'kpi-project') {
		let center = el.classList.contains('center')
		let swiperInstance = new Swiper('#' + el.id, {
			slidesPerView: 5,
			centeredSlides: center, 
			speed: 200,
			navigation: {
				nextEl: '.swiper-nav-next',
				prevEl: '.swiper-nav-prev',
			}
		})
	} else if (type == 'partners') {
		let swiperInstance = new Swiper('#' + el.id, {
			slidesPerView: 'auto',
			spaceBetween: 16,
			speed: 200,
			loopedSlides: 6,
			loop: true,
			breakpoints: {
				720: {
					spaceBetween: 32,
				}
			},
			navigation: {
				nextEl: '.swiper-nav-next',
				prevEl: '.swiper-nav-prev',
			},
			autoplay: {
				delay: 1000,
				disableOnInteraction: false,
				waitForTransition: false
			},
		})
	} else {
		let swiperInstance = new Swiper('#' + el.id, {
			slidesPerView: 'auto',
			spaceBetween: 8,
			speed: 200,
			breakpoints: {
				720: {
					spaceBetween: 32,
				}
			},
			navigation: {
				nextEl: '.swiper-nav-next',
				prevEl: '.swiper-nav-prev',
			}
		})
	}
}
