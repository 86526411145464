<template>
    <div class="input-toggle" :class="[state ? 'on' : 'off', disabled ? 'op-64' : '']">
        <span>{{ state }}</span>
    </div>
</template>

<script>
    import gsap                 from 'gsap'
    import ScrollToPlugin       from 'gsap/ScrollToPlugin';

    gsap.registerPlugin(ScrollToPlugin); 

    export default {
        props: ['state', 'disabled'],

        data() {
            return {
            }
        },

        methods: {
        }
    }
</script>