<template>
    <div class="menu-wrapper" :class="[mainmenuOpen ? 'main-open' : '',wrapperClass, submenuOpen ? 'open' : '', color && !fixed ? color : '', fixed ? 'stick' : '', location, opaque ? 'opaque' : '', submenuOpen ? 'open' : '', small ? 'small' : '']">
        <div class="menu-container menu-main" ref="menuContainer" :class="[mainmenuOpen || submenuOpen ? 'open' : '', color && !fixed ? color : '', fixed ? 'stick' : '', location, opaque ? 'opaque' : '', submenuOpen ? 'open' : '', small ? 'small' : '']">
            <div class="menu-overlay" v-if="submenuOpen" @mouseover="menu(event=$event, item=false, type='close')"/>
            <div class="menu-overlay" v-if="languageOpen" @click="languageOpen = false"/>

            <div class="cols menu-cols">
                <div class="col sm-12 rw nw space middle menu-col">
                    <a class="logo cl middle" :href="'/' + (currentLanguage.code === 'en' ? '' : currentLanguage.code)"><logo/></a>

                    <div class="menu-actions">
                        <a class="menu-action hide-sm" @click="menu(event=$event, item=false, type=closeOrHide)">
                            <div class="icon cl nw center middle">
                                <svg v-if="submenuOpen" width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m10 19.9 8.9-8.9 1.414 1.414L13.728 19H30v2H13.929l6.385 6.385-1.415 1.414-8.899-8.9Z" fill="currentColor"/></svg>
                                <svg v-else-if="mainmenuOpen" width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m18.586 20-7.778 7.778 1.414 1.414L20 21.414l7.778 7.778 1.414-1.414L21.414 20l7.778-7.778-1.414-1.414L20 18.586l-7.778-7.778-1.414 1.414L18.586 20Z" fill="currentColor"/></svg>
                                <svg v-else width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 14h20v2H10v-2ZM10 24h16v2H10v-2Z" fill="currentColor"/></svg>
                            </div>

                            <span class="hidden" v-html="__('menu')"/>
                        </a>

                        <language-menu v-if="currentSite != 'Gutami' || currentSite != ''" @toggle="toggleLang" :active="languageOpen" :languages="languages" :currentLanguage="currentLanguage"/>

                        <a class="menu-action" @click="openSearch">
                            <div class="icon">
                                <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.071 26.213c3.667 3.667 9.472 3.89 13.4.672l7.106 7.106 1.414-1.414-7.106-7.106c3.219-3.928 2.995-9.733-.672-13.4-3.905-3.905-10.237-3.905-14.142 0-3.905 3.905-3.905 10.237 0 14.142Zm1.414-1.414A8 8 0 1 0 23.8 13.485 8 8 0 0 0 12.485 24.8Z" fill="currentColor"/></svg>
                            </div>
                            <span class="hidden" v-html="__('search')"/>
                        </a>
                    </div>
                </div>
            </div>

            <nav class="menu-content" :class="[mainmenuOpen ? 'open' : '']" ref="menuWrapper">
                <div class="cols">
                    <div class="col sm-12 cl center middle menu-col">
                        <ul class="rw middle">
                            <li class="hide-sm">
                                <span class="label" @click="menu(event=$event, item=false, type='close')" v-html="__('menu')"/>
                            </li>

                            <li class="menu-item" v-for="(menuItem, index) in content" :key="index">
                                <a :href="menuItem.url" @click="menuItem.children ? menu($event, menuItem, type='main', index) : false" @mouseover="menu($event, menuItem, type='main', index)" :class="activeMenuItem == index ? 'curr' : ''">
                                    <span v-html="menuItem.label"/>
                                    <div v-if="menuItem.children" class="icon">chevron_right</div>
                                </a>
                            </li>
                        </ul>

                        <slot/>
                    </div>
                </div>
            </nav>

            <div class="menu-sub-wrapper" ref="subMenuWrapper" :class="[submenuOpen ? 'open' : '', transition ? 'transition' : '']">
                <div class="cols cl">
                    <div class="col sm-12 rw space middle menu-col">
                        <div class="indicator" ref="indicator"></div>

                        <ul class="menu-sub" ref="sub">
                            <li class="hide-sm">
                                <span class="label" @click="menu(event=$event, item=false, type='back')" v-html="__('back')"/>
                            </li>

                            <li class="menu-item child hide-sm">
                                <a :href="subMenu.url" :title="subMenu.title">
                                    <span>{{ subMenu.label }}</span>
                                </a>
                            </li>

                            <li class="menu-item child" v-for="(item, index) in subMenu.children" :key="index">
                                <a :href="item.url" @click="menu($event, item, type='sub')" :title="subMenu.title">
                                    <span v-html="item.label"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <search ref="search" :mobile="isMobile" :currentLanguage="currentLanguage"/>
        </div>

        <cookie-consent/>
    </div>
</template>

<script setup>
    import gsap           from 'gsap'
    import ScrollToPlugin from 'gsap/ScrollToPlugin'

    import Logo           from '../components/Logo'
    import Search         from '../components/Search'
    import LanguageMenu   from '../components/LanguageMenu'
    import CookieConsent  from '../components/CookieConsent'

    gsap.registerPlugin(ScrollToPlugin)
</script>

<script>
    export default {
        props: ['location', 'languages', 'currentLanguage', 'content', 'translations', 'translations_urls', 'currentSite'],

        data() {
            return {
                menuItems       : [],
                count           : 0,
                activeMenuItem  : -1,
                scrolled        : 0,
                transition      : true,
                mobileMenuState : false,
                mainmenuOpen    : false,
                submenuOpen     : false,
                subMenu         : false,
                search          : false,
                languageOpen    : false,
                width           : screen.width,
                active          : false
            }
        },

        beforeMount () {
            this.width = document.documentElement.clientWidth
            window.addEventListener('resize', this.screenSize, false)
            window.addEventListener('scroll', this.onScroll  , false)
        },

        beforeDestroy() {
            window.removeEventListener('resize', this.screenSize)
            window.removeEventListener('scroll', this.onScroll  )
        },

        mounted() {
            setTimeout(() => {
                this.active = true
            }, 1000);
        },

        methods: {
            toggleLang(event=false) {
                if (this.mainmenuOpen || this.submenuOpen) this.closeMenu()
                this.languageOpen = !this.languageOpen 
            },

            closeMenu () {
                this.activeMenuItem = -1
                this.mainmenuOpen = false
                this.submenuOpen  = false
                this.$refs.menuWrapper.removeAttribute('style')
                this.$refs.subMenuWrapper.removeAttribute('style')
            },

            increment () {
                this.count++
            },

            onScroll (e) {
                this.scrolled = window.top.scrollY
            },

            openSearch () {
                this.$refs.search.toggleSearch()
            },

            menu (event=false, item=false, type=false, index=-2) {
                if (index == this.activeMenuItem) return
                // else if (event.type == 'click' && this.activeMenuItem > 0 && !this.isMobile && type != 'close') return
                if (type != 'sub') event.preventDefault()
                this.languageOpen = false
                if (index != -1 || type == 'close') this.activeMenuItem = index
                if (type == 'open' || type == 'close') return !this.isMobile ? this.dropMenu(this.$refs.subMenuWrapper, true) : this.slideMenu(this.$refs.menuWrapper, true)
                if (type == 'back') return this.slideMenu(this.$refs.subMenuWrapper)
                if (item && event) {
                    this.subMenu = item
                    if (this.isMobile) {
                        if (type != 'sub') this.slideMenu(this.$refs.subMenuWrapper)
                    } else {
                        if (type == 'sub') {
                            this.dropMenu(true)
                        } else if (type != 'sub') {
                            this.transition = true
                            gsap.set(this.$refs.sub, {
                                opacity: 0,
                                onComplete: () => {
                                    this.dropMenu(this.$refs.subMenuWrapper, this.activeMenuItem == item, event.target.parentNode)
                                }
                            })
                        }
                    }
                }
            },

            dropMenu (menu=false, close=false, parentNode=false) {
                if (menu) { 
                    let state = (menu.classList ? !menu.classList.contains('open') : false) || !close
                    this.submenuOpen = state

                    if (parentNode) {
                        if (!this.submenuOpen) {
                            gsap.set(this.$refs.sub,       { x: parentNode.offsetLeft })
                            gsap.set(this.$refs.indicator, { x: parentNode.offsetLeft })
                        }

                        this.transition = false

                        gsap.to(this.$refs.sub, 0.24, {
                            ease: "power4.inOut",
                            opacity: 1,
                            x: parentNode.offsetLeft
                        })

                        gsap.to(this.$refs.indicator, 0.4, {
                            ease: "power4.inOut",
                            css: {
                                x: (parentNode.offsetLeft + 8),
                                width: (parentNode.offsetWidth - 16),
                                onComplete: () => {
                                    gsap.to(this.$refs.subMenuWrapper, 0.24, {
                                        ease: "power4.inOut",
                                        css: {
                                            height: this.$refs.subMenuWrapper.querySelector('.menu-sub').offsetHeight
                                        }
                                    })
                                }
                            }
                        })
                    }
                }
            },

            slideMenu (menu=false, main=false) {
                if (menu) {
                    let state = !menu.classList.contains('open')

                    if (main) { this.mainmenuOpen = state } else { this.submenuOpen  = state }

                    gsap.to(menu, !state ? 0.64 : 0.32, {
                        ease: "expo.out",
                        x: !state ? -menu.offsetWidth : 0,
                        onComplete: () => {
                            if (!state) gsap.set(menu, { x: menu.offsetWidth })
                        }
                    })
                }
            },

            screenSize() {
                this.width = document.documentElement.clientWidth
            }
        },

        computed: {
            fixed () {
                return this.scrolled > this.offset
            },

            opaque () {
                return document.querySelector('body').classList.contains('opaque-menu')
            },

            color () {
                return document.querySelector('body').classList.contains('top-dark') ? 'dark' : 'light'
            },

            small () {
                return false
            },

            wrapperClass () {
                return this.isMobile ? 'mobile' : 'desktop'
            },

            isMobile () {
                return this.width <= 800
            },

            offset () {
                return this.isMobile ? 24 : 40
            },

            closeOrHide () {
                return !this.submenuOpen ? 'close' : 'back'
            },
        },

        // watch: {
        //     wrapperClass(val=false) {
        //         this.closeMenu()
        //     }
        // }
    }
</script>
