<template>
    <div class="search-fixture" v-show="active" @click.self="toggleSearch">
        <div class="search-wrapper light" ref="searchWrapper">
            <div class="cols cl center np">
                <div class="col cl sm-12 md-8 lg-7 np">
                    <div class="search-container">
                        <form action="/search" autocomplete="off" ref="searchForm" class="search-form" method="get">
                            <svg v-if="mobile" class="icon" @click="searchMethod"width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.071 26.213c3.667 3.667 9.472 3.89 13.4.672l7.106 7.106 1.414-1.414-7.106-7.106c3.219-3.928 2.995-9.733-.672-13.4-3.905-3.905-10.237-3.905-14.142 0-3.905 3.905-3.905 10.237 0 14.142Zm1.414-1.414A8 8 0 1 0 23.8 13.485 8 8 0 0 0 12.485 24.8Z" fill="currentColor"/></svg>
                            <svg v-else class="icon" @click="searchMethod" width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M25.6 46.8c4.978 4.978 12.86 5.282 18.193.913l9.647 9.647 1.92-1.92-9.647-9.647c4.37-5.333 4.065-13.215-.913-18.193-5.302-5.302-13.898-5.302-19.2 0s-5.302 13.898 0 19.2Zm1.92-1.92c4.242 4.242 11.118 4.242 15.36 0 4.241-4.241 4.241-11.118 0-15.36-4.242-4.241-11.119-4.241-15.36 0-4.242 4.242-4.242 11.119 0 15.36Z" fill="currentColor"/></svg>
                            <input ref="searchField" type="text" name="query" v-model="searchQuery" :placeholder="placeholder" v-on:input="searchMethod" v-on:keydown.enter.prevent="searchMethod">
                            <svg v-if="mobile" class="close" width="40" @click="toggleSearch" height="40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m18.586 20-7.778 7.778 1.414 1.414L20 21.414l7.778 7.778 1.414-1.414L21.414 20l7.778-7.778-1.414-1.414L20 18.586l-7.778-7.778-1.414 1.414L18.586 20Z" fill="currentColor"/></svg>
                            <svg v-else class="close" @click="toggleSearch" width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m29.022 27.98-1.415 1.413L38.214 40 27.607 50.607l1.415 1.414 10.606-10.607 10.607 10.607 1.414-1.414L41.042 40 51.65 29.393l-1.414-1.414-10.607 10.607-10.606-10.607Z" fill="currentColor"/></svg>
                            <loader :loading="searching"/>
                        </form>

                        <template v-if="!searching">
                            <ul v-if="results.items && results.items.length > 0" class="search-results">
                                <li v-for="(result, index) in results.items" :class="index == resultIndex ? 'active' : ''">
                                    <!-- <result-vacancy     v-if="result.api_type == 'vacancy'"  :content="result"/>
                                    <result-employee    v-if="result.api_type == 'employee'" :content="result"/>
                                    <result-news        v-if="result.api_type == 'news'"     :content="result"/>
                                    <result-partner     v-if="result.api_type == 'partner'"  :content="result"/>
                                    <result-page        v-if="result.api_type == 'page'"     :content="result"/>
                                    <result-page        v-if="result.api_type == 'project'"  :content="result"/> -->
                                    <result-page  :content="result"/>
                                </li>
                            </ul>

                            <div class="no-results" v-else-if="searchQuery.length > 2">
                                <span>{{ __("search.no_result") }}</span> 
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios                 from 'axios'
    import gsap                 from 'gsap'
    import ScrollToPlugin       from 'gsap/ScrollToPlugin';
    // import { mapState }         from 'vuex'
    // import { mapGetters }       from 'vuex'
    // import { mapActions }       from 'vuex'

    gsap.registerPlugin(ScrollToPlugin); 

    export default {
        props: ['mobile', 'placeholder', 'currentLanguage'],
        // components: [Loader, ResultPage],

        data() {
            return {
                active      : false,
                searchQuery : '',
                resultIndex : 0,
                loading     : false,
                results     : false
            }
        },

        beforeMount () {
            window.addEventListener('keydown', this.handleKeydown, null)
        },

        beforeDestroy() {
            if (!this.ftr) window.removeEventListener("scroll", this.onScroll)
            window.removeEventListener('keydown', this.handleKeydown)
        },

        methods: {
            searchMethod(event=false) {
                if (event.keyCode == 13) {
                    event.preventDefault(); 
                    if (this.results && 'items' in this.results) {
                        return window.location = this.results.items[this.resultIndex].meta.html_url
                    }
                }

                event.preventDefault()
                this.loading     = true
                this.resultIndex = 0

                let data = {
                    "types": ['news.NewsItem', 'vacancies.Vacancy', 'flex.FlexPage', 'home.HomePage', 'projects.ProjectItem', 'forms.ContactPage'],
                    "query": this.searchQuery,
                    "locale": this.currentLanguage.code
                }

                if (this.searchQuery.length > 2) {
                    if (data) {
                        this.loading = false
                        let promises = []
                        let resultList = {
                            'items' : [],
                            'meta'  : []
                        }

                        if (data.types.length) {
                            data.types.forEach(type => {
                                promises.push(
                                    new Promise((resolve, reject) => {
                                        axios.get('/api/v2/pages?type=' + type + '&locale=' + data.locale + '&fields=*&search=' + data.query)
                                            .then( (response) => {
                                                resultList.items.push(...response.data.items)
                                                resolve(response.data)
                                            })
                                            .catch(function (error) {
                                                // console.log(error)
                                                reject(error);
                                            })
                                        })
                                );
                            });

                            Promise.all(promises).then(() => {
                                this.results = resultList
                            });
                        }
                    }
                } else {

                //     this.$store.dispatch('search/reset')
                }
            },

            toggleSearch () {
                this.active      = !this.active
                this.resultIndex = 0

                let reset = {
                    y: -100,
                    opacity: 0
                }

                if (this.active) {
                    gsap.set(this.$refs.searchWrapper, reset)
                    gsap.to(this.$refs.searchWrapper, 0.20, {
                        ease: "expo.out",
                        y: 0,
                        opacity: 1,

                        onComplete: () => {
                            this.$refs.searchField.focus()
                        }
                    })
                } else {
                    gsap.to(this.$refs.searchWrapper, 0.32, reset)
                    this.searchQuery = ''
                }
            },

            keyNav (code) {
                if (this.results && 'items' in this.results) {
                    let count       = this.results.items.length - 1,
                        itemHeight  = document.querySelector('.search-results .active').offsetHeight

                    if (code == '38') {                 // Navigate up
                        if (this.resultIndex > 0) {
                            this.resultIndex -= 1
                        } else {
                            this.resultIndex = count
                        }
                    } else if (code == '40') {          // Navigate down
                        if (this.resultIndex < count) {
                            this.resultIndex += 1
                        } else {
                            this.resultIndex = 0
                        }
                    }

                    gsap.to(document.querySelector('.search-results'), 0.12, {
                        scrollTo: {
                            y: this.resultIndex * itemHeight,
                            offsetY: itemHeight
                        }
                    })
                }
            },

            handleKeydown (e) {
                switch (e.keyCode) {
                    case 27 :
                        if (this.active) this.toggleSearch()
                        break
                    case 220 : // Activate with S
                        if (!this.active) this.toggleSearch()
                        break
                    case 38 :
                    case 40 :
                        e.preventDefault()
                        if (this.results && 'items' in this.results) this.keyNav(e.keyCode)
                        break
                }
            }
        },

        computed: {
            // results() {
            //     return false
            //     // return this.$store.getters['search/results']
            // },

            searching () {
                // return this.$store.getters['search/loading']
                return false
            }
        }
    }
</script>