<template>
    <a :href="content.meta.html_url" class="search-result news">
        <div class="content rw nw space base">
            <span class="title">{{ content.title }}</span>
            <span class="type" v-if="content.api_type">{{ __(content.api_type) }}</span>
        </div>
    </a>
</template>

<script>
    import gsap     from 'gsap'
    import axios    from 'axios'

    export default {
        props: ['content'],

        data() {
            return {
            }
        },
    }
</script>