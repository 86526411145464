'use strict'

import * as cookie from './cookie'

let translations = {
    "nl": {
        "cookie.title": "We gebruiken cookies",
        "cookie.description": "We gebruiken standaard cookies zodat de website goed functioneert. Persoonlijke cookies kun je accepteren voor een optimale ervaring.",
        "cookie.customize.title": "Cookies aanpassen",
        "cookie.customize.description": "Stem je cookievoorkeuren af voor een gepersonaliseerde browse-ervaring.",
        "Always active": "Altijd actief",
        "cookie.functional.title": "Noodzakelijk",
        "cookie.functional.description": "Voor standaard cookies hebben we je toestemming niet nodig. We zullen die cookies daarom altijd gebruiken zodra je onze website bezoekt.",
        "cookie.performance.title": "Prestaties",
        "cookie.performance.description": "Met deze cookies kunnen wij en derde partijen je persoonlijke advertenties laten zien en kan je informatie delen op social media. Wil je meer weten over onze cookies of jouw cookiekeuze later weer aanpassen? Dat kan in onze cookieverklaring.",
        "Back": "Terug",
        "Customize": "Aanpassen",
        "Accept settings": "Opslaan",
        "Accept cookies": "Akkoord",
        "search.no_result": "Geen resultaten gevonden…",
        "page": "Pagina",
        "news": "Nieuwsbericht",
        "contact": "Contact pagina",
        "vacancy": "Vacature",
        "event": "Evenement",
        "menu": "Menu",
        "back": "Terug",
        "project": "Project",
        "search": "Zoeken.."
    },
    "de": {
    	"cookie.title": "Customize cookies",
        "cookie.description": "The opt-in cookie banner ensures that cookies are only set after consent given.",
        "cookie.customize.title": "Customize cookies",
    	"cookie.customize.description": "Customize cookies",
        "Always active": "Always active",
        "cookie.functional.title": "Functional",
        "cookie.functional.description": "For standard cookies, we do not require your consent. Therefore, we will always use those cookies as soon as you visit our website.",
        "cookie.performance.title": "Performance",
        "cookie.performance.description": "These cookies allow us and third parties to show you personalized advertisements and enable you to share information on social media. Would you like to learn more about our cookies or adjust your cookie preferences later? You can do so in our cookie statement.",
        "Back": "Back",
        "Customize": "Customize",
        "Accept settings": "Accept settings",
        "Accept cookies": "Accept cookies",
        "search.no_result": "Geen resultaten gevonden..",
        "menu": "Menu",
        "back": "Zurück",
        "search": "Suchen.."
    },
    "en": {
    	"cookie.title": "Customize cookies",
        "cookie.description": "We use standard cookies to ensure the website functions properly. You can accept personalized cookies for an optimal experience.",
    	"cookie.customize.title": "Customize cookies",
    	"cookie.customize.description": "Tailor your cookie preferences for a personalized browsing experience.",
        "Always active": "Always active",
        "cookie.functional.title": "Functional",
        "cookie.functional.description": "We use functional cookies to enhance your browsing experience. These cookies are essential for the proper functioning of our site and do not require your consent.",
        "cookie.performance.title": "Performance",
        "cookie.performance.description": "These cookies allow us and third parties to show you personalized advertisements and enable you to share information on social media. Would you like to learn more about our cookies or adjust your cookie preferences later? You can do so in our cookie statement.",
        "Back": "Back",
        "Customize": "Customize",
        "Accept settings": "Accept settings",
        "Accept cookies": "Accept cookies",
        "search.no_result": "No results matching your query..",
        "page": "Page",
        "news": "News",
        "contact": "Contact",
        "vacancy": "Vacancy",
        "event": "Event",
        "project": "Project",
        "menu": "Menu",
        "back": "Back",
        "search": "Search.."
    }
}

let setting = cookie.get('lang')
let locale  = setting ? setting.slice(0, 2) : 'en'

export function translate (string, variables=false) {
    if (!translations[locale].hasOwnProperty(string)) return console.log('Translation not found: ', string)
    return variables ? translations[locale][string].replace(/%%/g, (i => _ => variables[i++])(0)) : translations[locale][string]
}
