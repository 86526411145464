<template>
    <div class="cookies-wrapper" v-show="visible">
        <div class="cookies-container" :class="customize ? 'customize' : ''">
            <div class="close knob cl center middle" @click="consent()">
                <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m18.586 20-7.778 7.778 1.414 1.414L20 21.414l7.778 7.778 1.414-1.414L21.414 20l7.778-7.778-1.414-1.414L20 18.586l-7.778-7.778-1.414 1.414L18.586 20Z" fill="currentColor"></path></svg>
            </div>

            <div class="cookies-inner">
                <h1 v-if="customize">{{ __('cookie.customize.title') }}</h1>
                <h1 v-else>{{ __('cookie.title') }}</h1>
                <p v-if="!customize">{{ __('cookie.description') }}</p>
                <p v-if="customize">{{ __('cookie.customize.description') }}</p>

                <ul class="settings" v-if="customize">
                    <li class="rw space nw oh" @click="setting.state = 'disabled' in setting ? setting.state : !setting.state" v-for="setting in settings">
                        <div class="cl nw">
                            <small>{{ setting.name }} <span class="op-40" v-if="setting.disabled">{{ __('Always active') }}</span></small>
                            <span class="description">{{ setting.description }}</span>
                        </div>

                        <input-toggle :state="setting.state" :disabled="setting.disabled"/>
                    </li>
                </ul>

                <div class="button-wrap">
                    <button @click="customize = !customize" class="button secondary">
                        <span v-if="!customize">{{ __('Customize') }}</span>
                        <span v-else>{{ __('Back') }}</span>
                    </button>

                    <button @click="consent()" class="button primary">
                        <span v-if="!customize">{{ __('Accept cookies') }}</span>
                        <span v-else>{{ __('Accept settings') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import gsap                 from 'gsap'
    import ScrollToPlugin       from 'gsap/ScrollToPlugin';
    import * as cookie          from '../cookie'

    import InputToggle from './InputToggle'

    gsap.registerPlugin(ScrollToPlugin)
</script>

<script>
    export default {
        data() {
            return {
                customize   : false,
                hidden      : true,
                settings    : [
                    {
                        type        : 'functional',
                        name        : this.__('cookie.functional.title'),
                        description : this.__('cookie.functional.description'),
                        state       : true,
                        disabled    : true
                    }, {
                        type        : 'performance',
                        name        : this.__('cookie.performance.title'),
                        description : this.__('cookie.performance.description'),
                        state       : false,
                    },
                ],
                cookie      : cookie.get('consent')
            }
        },

        mounted() {
            // this.cookie = cookie.get('consent')

            if (!this.cookie) {
                this.hidden = false
            } else {

                if (this.cookie == "functional" || this.cookie == "performance") {
                    // Standard cookie here..
                }

                if (this.cookie == "performance") {
                    // Performance cookie here..
                    
                }

            }
        },

        methods: {
            consent() {
                let list = ''
                this.settings.forEach(setting => {
                    if (setting.state) {
                        list += setting.type
                    }
                })

                console.log('User consented to: ', list)
                this.hidden = true
                window.reopenCookies = false
                cookie.set('consent', list)
            }
        },

        computed: {
            visible () {
                return window.reopenCookies || !this.hidden
            }
        }
    }
</script>