<template>
    <div class="loader-wrapper" :class="loading ? 'loading' : ''">
        <div class="loader-container"></div>
    </div>
</template>

<script>
    import gsap from 'gsap'

    export default {
        name: 'Loader',
        props: ['loading'],

        data() {
            return {
            }
        },

        methods: {
        },

        computed: {
        }
    }
</script>