'use strict'

import { createApp } 	 from 'vue'
import { gsap } 		 from 'gsap'
import simpleParallax 	 from 'simple-parallax-js'

// Local scripts
import * as slider 		 from './slider'
import * as cookie 		 from './cookie'
import * as translations from './translations'

// Vue components 
import PageMenu 	     from './components/PageMenu.vue'
import Logo 		     from './components/Logo.vue'
import Loader 		     from './components/Loader'
import ResultPage 	     from './components/ResultPage'

function scrollToPos(e, anchor, offset=0) {
	e.preventDefault()
	let href = document.querySelector(anchor.getAttribute("href"))
    if (href) gsap.to( window, 0.4, {ease: 'Expo.easeOut', scrollTo: href.offsetTop - offset})
}

window.addEventListener('load', () => {

	// Start cookies
	cookie.init()

	// Init vue & components
	let components = document.querySelectorAll('.component')
	if (components) {
		components.forEach(component => {
			let app = createApp()

			// Components
			app.component('Logo', Logo)
			app.component('PageMenu', PageMenu)
			app.component('Loader', Loader)
			app.component('ResultPage', ResultPage)

			// Config
			app.config.globalProperties.__ = translations.translate

			// Start Vue app
			app.mount(component)
		})
	}

	// Parallax header images
    let parallaxImages = document.querySelectorAll('.para img')
    if (parallaxImages) {
		new simpleParallax(parallaxImages, {
			orientation: 'down',
			overflow: true
		})
	}

	// Start swiperInstances
	let swiperInstances = document.querySelectorAll('.swiper-container')
	if (swiperInstances) {
		swiperInstances.forEach(instance => {
			slider.swiper(instance)
		})
	}

	// Register scrollToAnchors
	let scrollToAnchors = document.querySelectorAll('a.scroll-to')
	if (scrollToAnchors) {
		scrollToAnchors.forEach(anchor => {
			anchor.addEventListener('click', () => {
				scrollToPos(event, anchor, document.querySelector('.menu-container').offsetHeight)
			})
		})
	}
})
